import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './LandingPage.css';

const LandingPage = () => {
  const navigate = useNavigate();
  const [username, setUsername] = useState(() => {
    return localStorage.getItem('username') || '';
  });

  const [dailyPlayers, setDailyPlayers] = useState(0);
  const [totalPlayers, setTotalPlayers] = useState(0);
  const [activeGames, setActiveGames] = useState(0);

  useEffect(() => {
    const animationDuration = 5000;
    const steps = 500;
    const dailyIncrement = 100 / steps;
    const totalIncrement = 1000 / steps;
    const activeIncrement = 100 / steps;
    const stepDuration = animationDuration / steps;

    let currentStep = 0;

    const timer = setInterval(() => {
      if (currentStep < steps) {
        setDailyPlayers(prev => Math.min(Math.ceil(prev + dailyIncrement), 100));
        setTotalPlayers(prev => Math.min(Math.ceil(prev + totalIncrement), 1000));
        setActiveGames(prev => Math.min(Math.ceil(prev + activeIncrement), 100));
        currentStep++;
      } else {
        clearInterval(timer);
      }
    }, stepDuration);

    return () => clearInterval(timer);
  }, []);

  const handlePlayGame = () => {
    const finalUsername = username.trim() || 'Unnamed Player';
    localStorage.setItem('username', finalUsername);
    navigate('/rooms');
  };

  return (
    <div className="landing-container">
      <div className="landing-content">
        <h1 className="landing-title">Connections Racer</h1>
        <div className="landing-subtitle">The Competitive Connections Game</div>
        
        <div className="game-preview">
          <div className="preview-grid">
            {['COMPETE', 'SOLVE', 'RACE', 'WIN'].map((word, index) => (
              <div key={index} className="preview-box">{word}</div>
            ))}
          </div>
        </div>

        <div className="login-section">
          <input
            type="text"
            placeholder="Enter your username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            className="username-input"
            onKeyPress={(e) => {
              if (e.key === 'Enter') handlePlayGame();
            }}
          />
          <button className="play-button" onClick={handlePlayGame}>
            Start Racing
          </button>
        </div>

        <div className="landing-features">
          <div className="feature">
            <span className="feature-emoji">⚡️</span>
            <h3>Speed Racing</h3>
            <p>Race against the clock to solve connections faster than others</p>
          </div>
          <div className="feature">
            <span className="feature-emoji">🏆</span>
            <h3>Global Competition</h3>
            <p>Compete with players worldwide for the fastest solve times</p>
          </div>
          <div className="feature">
            <span className="feature-emoji">📊</span>
            <h3>Live Rankings</h3>
            <p>Track your performance and climb the global leaderboard</p>
          </div>
        </div>

        <div className="competitive-stats">
          <div className="stat">
            <span className="stat-number">{dailyPlayers}+</span>
            <span className="stat-label">Daily Players</span>
          </div>
          <div className="stat">
            <span className="stat-number">{totalPlayers}+</span>
            <span className="stat-label">Total Players</span>
          </div>
          <div className="stat">
            <span className="stat-number">{activeGames}+</span>
            <span className="stat-label">Daily Games</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandingPage;