import React, { useState, useEffect } from 'react';
import './theme.css';
import './App.css';
import Game from './components/Game';
import ThemeToggle from './components/ThemeToggle';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import LandingPage from './components/LandingPage';
import GameRoom from './components/GameRoom';
import RoomsList from './components/RoomsList';
import CreateRoom from './components/CreateRoom';

function App() {
  const [isDarkMode, setIsDarkMode] = useState(() => {
    const saved = localStorage.getItem('theme');
    return saved === 'dark';
  });

  useEffect(() => {
    document.documentElement.setAttribute('data-theme', isDarkMode ? 'dark' : 'light');
    localStorage.setItem('theme', isDarkMode ? 'dark' : 'light');
  }, [isDarkMode]);

  const handleThemeToggle = () => {
    setIsDarkMode(prev => !prev);
  };

  return (
    <BrowserRouter basename={process.env.PUBLIC_URL}>
      <div className="App">
        <ThemeToggle isDark={isDarkMode} onToggle={handleThemeToggle} />
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/rooms" element={<RoomsList />} />
          <Route path="/create-room" element={<CreateRoom />} />
          <Route path="/practice" element={<Game mode="practice" />} />
          <Route path="/computer" element={<Game mode="computer" />} />
          <Route path="/room/:roomId" element={<GameRoom />} />
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
