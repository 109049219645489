import { useState, useEffect } from 'react';
import FingerprintJS from '@fingerprintjs/fingerprintjs';

const useAnonymousId = () => {
  const [anonymousId, setAnonymousId] = useState(null);

  useEffect(() => {
    async function getFingerprint() {
      // Check localStorage first
      const stored = localStorage.getItem('anonymousId');
      if (stored) {
        setAnonymousId(stored);
        return;
      }

      // Generate new fingerprint if none exists
      const fp = await FingerprintJS.load();
      const result = await fp.get();
      const newId = result.visitorId;
      
      localStorage.setItem('anonymousId', newId);
      setAnonymousId(newId);
    }

    getFingerprint();
  }, []);

  return anonymousId;
};

export default useAnonymousId; 