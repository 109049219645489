import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import io from 'socket.io-client';
import './RoomsList.css';

const RoomsList = () => {
  const [rooms, setRooms] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const socket = io(process.env.REACT_APP_BACKEND_URL || 'https://connectionracer-backend.onrender.com');

    socket.on('connect', () => {
      socket.emit('getRooms');
    });

    socket.on('roomsList', (roomsList) => {
      setRooms(roomsList);
      setLoading(false);
    });

    socket.on('roomsUpdate', (updatedRooms) => {
      setRooms(updatedRooms);
    });

    return () => {
      socket.disconnect();
    };
  }, []);

  const startGame = (mode) => {
    switch (mode) {
      case 'practice':
        navigate('/practice');
        break;
      case 'computer':
        navigate('/computer');
        break;
      default:
        navigate(`/room/${mode}`);
    }
  };

  return (
    <div className="rooms-container">
      <Link to="/" className="home-link">← Home</Link>
      <div className="rooms-header">
        <h2>Game Modes</h2>
      </div>
      
      <div className="rooms-grid">
        {/* Practice Mode */}
        <div className="room-card">
          <div className="room-info">
            <h3>Practice Mode</h3>
            <div className="game-status">Play at your own pace</div>
          </div>
          <div className="room-actions">
            <button 
              className="join-button"
              onClick={() => startGame('practice')}
            >
              Start Practice
            </button>
          </div>
        </div>

        {/* Computer Opponent Mode */}
        <div className="room-card">
          <div className="room-info">
            <h3>VS Computer</h3>
            <div className="game-status">Race against AI opponent</div>
          </div>
          <div className="room-actions">
            <button 
              className="join-button"
              onClick={() => startGame('computer')}
            >
              Start Game
            </button>
          </div>
        </div>

        {/* Create Room Card */}
        <div className="room-card create-room">
          <div className="room-info">
            <h3>Create Room</h3>
            <div className="game-status">Start a new multiplayer game</div>
          </div>
          <div className="room-actions">
            <button 
              className="join-button"
              onClick={() => navigate('/create-room')}
            >
              Create Room
            </button>
          </div>
        </div>

        {/* Public Rooms Section */}
        <div className="public-rooms-section">
          <h3>Public Rooms</h3>
          {rooms.map(room => (
            <div key={room.id} className="room-card">
              <div className="room-info">
                <h3>{room.name || `Room #${room.id}`}</h3>
                <div className="player-count">
                  Players: {room.players.length}/4
                </div>
                <div className="game-status">
                  Status: {room.gameInProgress ? 'In Progress' : 'Waiting'}
                </div>
              </div>
              <div className="room-actions">
                <button 
                  className="join-button"
                  onClick={() => startGame(room.id)}
                  disabled={room.players.length >= 4 || room.gameInProgress}
                >
                  {room.gameInProgress ? 'Spectate' : 'Join'}
                </button>
                {!room.gameInProgress && (
                  <button 
                    className="copy-link-button"
                    onClick={() => {
                      navigator.clipboard.writeText(
                        `${window.location.origin}/room/${room.id}`
                      );
                    }}
                  >
                    Copy Invite Link
                  </button>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default RoomsList;