import React from 'react';
import './ThemeToggle.css';

const ThemeToggle = ({ isDark, onToggle }) => {
  return (
    <button 
      className={`theme-toggle ${isDark ? 'dark' : 'light'}`}
      onClick={onToggle}
      aria-label="Toggle dark mode"
    >
      <span className="icon sun">☀️</span>
      <span className="icon moon">🌙</span>
    </button>
  );
};

export default ThemeToggle; 