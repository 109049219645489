import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import io from 'socket.io-client';
import './CreateRoom.css';

const CreateRoom = () => {
  const [roomName, setRoomName] = useState('');
  const [isPublic, setIsPublic] = useState(true);
  const [isFriendsOnly, setIsFriendsOnly] = useState(false);
  const [roomLink, setRoomLink] = useState('');
  const [socket, setSocket] = useState(null);
  const navigate = useNavigate();

  const BACKEND_URL = process.env.REACT_APP_BACKEND_URL || 'https://connectionracer-backend.onrender.com';

  useEffect(() => {
    const newSocket = io(BACKEND_URL);
    setSocket(newSocket);

    newSocket.on('roomCreated', (response) => {
      if (response.roomId) {
        if (isFriendsOnly) {
          const roomLink = `${window.location.origin}/room/${response.roomId}`;
          setRoomLink(roomLink);
        } else {
          navigate(`/room/${response.roomId}`);
        }
      }
    });

    return () => {
      newSocket.disconnect();
    };
  }, [navigate, isFriendsOnly]);

  const handleCheckboxChange = (type) => {
    if (type === 'public') {
      setIsPublic(true);
      setIsFriendsOnly(false);
    } else {
      setIsPublic(false);
      setIsFriendsOnly(true);
    }
  };

  const handleCreateRoom = () => {
    if (socket && roomName) {
      socket.emit('createRoom', {
        name: roomName,
        isPublic,
        isFriendsOnly,
        creator: localStorage.getItem('username') || 'Unnamed Player'
      });
    }
  };

  return (
    <div className="create-room-modal">
      <h2>Create New Room</h2>
      <input
        type="text"
        placeholder="Room Name"
        value={roomName}
        onChange={(e) => setRoomName(e.target.value)}
        className="room-name-input"
      />
      
      <div className="room-type-selection">
        <label className="room-type-option">
          <input
            type="radio"
            checked={isPublic}
            onChange={() => handleCheckboxChange('public')}
            name="roomType"
          />
          <span className="room-type-label">Public Room</span>
          <p className="room-type-description">Anyone can join from the lobby</p>
        </label>
        
        <label className="room-type-option">
          <input
            type="radio"
            checked={isFriendsOnly}
            onChange={() => handleCheckboxChange('friends')}
            name="roomType"
          />
          <span className="room-type-label">Play with Friends</span>
          <p className="room-type-description">Share a private link to invite friends</p>
        </label>
      </div>

      {roomLink ? (
        <div className="room-link-container">
          <p>Share this link with your friends:</p>
          <div className="room-link">
            <input
              type="text"
              value={roomLink}
              readOnly
              className="room-link-input"
            />
            <button
              onClick={() => {
                navigator.clipboard.writeText(roomLink);
                // Optional: Add a copied confirmation
              }}
              className="copy-link-button"
            >
              Copy Link
            </button>
          </div>
          <button 
            onClick={() => navigate(`/room/${roomLink.split('/').pop()}`)}
            className="join-room-button"
          >
            Join Room
          </button>
        </div>
      ) : (
        <div className="modal-buttons">
          <button onClick={() => navigate('/rooms')} className="cancel-button">
            Cancel
          </button>
          <button onClick={handleCreateRoom} className="create-button">
            Create Room
          </button>
        </div>
      )}
    </div>
  );
};

export default CreateRoom; 