import React, { useState, useEffect } from 'react';
import './Game.css';
import io from 'socket.io-client';
import useAnonymousId from '../hooks/useAnonymousId';
import { Link } from 'react-router-dom';

const initialCategories = [
  {
    name: 'Things that are yellow',
    words: ['Banana', 'Sun', 'Lemon', 'Daffodil'],
    color: '#f4e285',
    completed: false,
  },
  {
    name: 'Types of trees',
    words: ['Oak', 'Maple', 'Pine', 'Birch'],
    color: '#a4be7b',
    completed: false,
  },
  {
    name: 'Ocean creatures',
    words: ['Shark', 'Whale', 'Dolphin', 'Octopus'],
    color: '#b8c0e4',
    completed: false,
  },
  {
    name: 'Musical instruments',
    words: ['Piano', 'Guitar', 'Violin', 'Drums'],
    color: '#b27bc4',
    completed: false,
  },
];

const shuffleArray = (array) => {
  const newArray = [...array];
  for (let i = newArray.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [newArray[i], newArray[j]] = [newArray[j], newArray[i]];
  }
  return newArray;
};

const isOneAway = (selected) => {
  if (selected.length !== 4) return false;
  
  // Check each category to see if we have exactly 3 words from it
  for (const category of initialCategories) {
    const wordsInCategory = selected.filter(word => 
      category.words.includes(word.word)
    ).length;
    
    if (wordsInCategory === 3) {
      return true;
    }
  }
  
  return false;
};

const getElementByText = (selector, text) => {
  const elements = document.querySelectorAll(selector);
  return Array.from(elements).find(element => 
    element.textContent.trim() === text.trim()
  );
};

const formatTime = (ms) => {
  const totalSeconds = Math.floor(ms / 1000);
  const mins = Math.floor(totalSeconds / 60);
  const secs = totalSeconds % 60;
  return `${mins}:${secs.toString().padStart(2, '0')}`;
};

const StatsPanel = ({ timer, gameStats }) => {
  return (
    <div className="stats-panel">
      <div className="timer">
        <h3>Time</h3>
        <div className="time-display">{formatTime(timer * 1000)}</div>
      </div>
      <div className="stats">
        <h3>Stats</h3>
        <div className="stat-item">
          <span>Best Success Time:</span>
          <span>{gameStats.bestTime === Infinity ? '--:--' : formatTime(gameStats.bestTime)}</span>
        </div>
        <div className="stat-item">
          <span>Average Success Time:</span>
          <span>{gameStats.averageTime ? formatTime(gameStats.averageTime) : '--:--'}</span>
        </div>
        <div className="stat-item">
          <span>Games Played:</span>
          <span>{gameStats.gamesPlayed}</span>
        </div>
        <div className="stat-item">
          <span>Success Rate:</span>
          <span>{Math.round(gameStats.successRate)}%</span>
        </div>
      </div>
    </div>
  );
};

const OpponentPanel = ({ opponents }) => {
  // Sort opponents by completed categories count and completion time
  const sortedOpponents = [...opponents].sort((a, b) => {
    const aComplete = a.completedCategories.length;
    const bComplete = b.completedCategories.length;
    
    if (aComplete === bComplete) {
      // If same number of completions, sort by completion time
      if (a.completionTime && b.completionTime) {
        return a.completionTime - b.completionTime;
      }
      return b.completionTime ? 1 : -1; // Put incomplete after complete
    }
    return bComplete - aComplete; // Most completions first
  });

  return (
    <div className="opponent-panel">
      <h3>Opponents</h3>
      {sortedOpponents.map((opponent, index) => (
        <div key={opponent.id} className="opponent-progress">
          <div className="opponent-info">
            <div className="opponent-name">
              {opponent.name}
              {opponent.completionTime && (
                <span className="completion-time">
                  {formatTime(opponent.completionTime)}
                </span>
              )}
            </div>
            <div className="opponent-lives">
              {[...Array(4)].map((_, i) => (
                <Heart key={i} broken={i >= (opponent.lives ?? 4)} />
              ))}
            </div>
          </div>
          <div className="category-indicators">
            {[0, 1, 2, 3].map(i => (
              <div 
                key={i}
                className={`category-indicator ${opponent.completedCategories[i] ? 'completed' : ''}`}
                style={{
                  backgroundColor: opponent.completedCategories[i] ? 
                    initialCategories[opponent.completedCategories[i] - 1].color : '#e6e6e6'
                }}
              />
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

const Heart = ({ broken }) => (
  <div className={`heart ${broken ? 'broken' : ''}`}>♥</div>
);

const getStorageKey = (mode) => {
  switch (mode) {
    case 'computer':
      return 'computerGameState';
    case 'practice':
      return 'practiceGameState';
    default:
      return `multiplayerGameState_${mode}`;
  }
};

const Game = ({ roomId, socket, mode }) => {
  // Load saved game state
  const storageKey = getStorageKey(mode);
  const savedState = JSON.parse(localStorage.getItem(storageKey)) || {};
  
  const [gameStartTime, setGameStartTime] = useState(() => {
    // If there's a saved state, use that time, otherwise use current time
    return savedState.gameStartTime || Date.now();
  });
  const [categories, setCategories] = useState(() => {
    if (savedState.categories) return savedState.categories;
    return initialCategories.map(cat => ({...cat, completed: false}));
  });
  const [shuffledWords, setShuffledWords] = useState(() => {
    if (savedState.shuffledWords) return savedState.shuffledWords;
    
    const allWords = initialCategories.flatMap(cat => 
      cat.words.map(word => ({
        word,
        categoryIndex: initialCategories.indexOf(cat)
      }))
    );
    return shuffleArray(allWords);
  });
  
  const [selectedWords, setSelectedWords] = useState(savedState.selectedWords || []);
  const [completedRows, setCompletedRows] = useState(savedState.completedRows || []);
  const [attempts, setAttempts] = useState(savedState.attempts ?? 4);
  const [timer, setTimer] = useState(savedState.timer || 0);
  const [gameActive, setGameActive] = useState(savedState.gameActive ?? true);
  const [opponents, setOpponents] = useState(savedState.opponents || []);
  const [showOneAway, setShowOneAway] = useState(false);
  const [shake, setShake] = useState(false);
  const [gameStats, setGameStats] = useState(() => {
    const saved = localStorage.getItem(`gameStats_${mode}`);
    return saved ? JSON.parse(saved) : {
      averageTime: 0,
      bestTime: Infinity,
      gamesPlayed: 0,
      successRate: 0,
      gamesWon: 0
    };
  });
  const anonymousId = useAnonymousId();
  
  const isGameActive = completedRows.length < 4 && attempts > 0;

  useEffect(() => {
    if (!anonymousId) return;
    
    const newSocket = io('http://localhost:5001', {
      withCredentials: true,
      query: { anonymousId, mode }
    });

    // Socket connection listeners
    newSocket.on('connect', () => {
      console.log('Connected to server');
    });

    newSocket.on('disconnect', () => {
      console.log('Disconnected from server');
    });

    // Game completion listener
    newSocket.on('statsUpdate', (newStats) => {
      setGameStats(newStats);
      localStorage.setItem(`gameStats_${mode}`, JSON.stringify(newStats));
    });

    // Handle game completion
    if (!isGameActive && gameActive) {
      const gameData = {
        time: timer,
        won: completedRows.length === 4
      };
      newSocket.emit('gameComplete', gameData);
    }

    // Cleanup on component unmount
    return () => {
      newSocket.disconnect();
    };
  }, [anonymousId, isGameActive, gameActive, timer, completedRows.length, mode]);

  useEffect(() => {
    if (!socket) return;
    
    socket.on('opponentProgress', (data) => {
      setOpponents(prevOpponents => {
        const updatedOpponents = [...prevOpponents];
        const opponentIndex = updatedOpponents.findIndex(o => o.id === data.id);
        if (opponentIndex !== -1) {
          updatedOpponents[opponentIndex].completedCategories = data.completedCategories;
        }
        return updatedOpponents;
      });
    });

    socket.on('opponentJoined', (opponent) => {
      setOpponents(prev => [...prev, opponent]);
    });

    socket.on('opponentLeft', (opponentId) => {
      setOpponents(prev => prev.filter(o => o.id !== opponentId));
    });

    return () => {
      if (socket) {
        socket.off('opponentProgress');
        socket.off('opponentJoined');
        socket.off('opponentLeft');
      }
    };
  }, [socket]);

  useEffect(() => {
    let interval;
    
    if (isGameActive) {
      interval = setInterval(() => {
        setTimer(prev => prev + 1);
      }, 1000);
    } else if (!isGameActive && gameActive) {
      // Game just ended
      setGameActive(false);
      clearInterval(interval);

      // Update game stats
      setGameStats(prev => {
        const newGamesPlayed = prev.gamesPlayed + 1;
        const gameWon = completedRows.length === 4;
        const newGamesWon = gameWon ? prev.gamesWon + 1 : prev.gamesWon;
        
        let newBestTime = prev.bestTime;
        let newAverageTime = prev.averageTime;
        
        if (gameWon) {
          // Update best time if this game was won and time is better
          newBestTime = timer < prev.bestTime ? timer : prev.bestTime;
          
          // Calculate new average time only for successful games
          const totalSuccessTime = (prev.averageTime * prev.gamesWon) + timer;
          newAverageTime = Math.round(totalSuccessTime / newGamesWon);
        }
        
        // Calculate success rate
        const newSuccessRate = (newGamesWon / newGamesPlayed) * 100;

        const newStats = {
          bestTime: newBestTime,
          averageTime: newAverageTime,
          gamesPlayed: newGamesPlayed,
          gamesWon: newGamesWon,
          successRate: newSuccessRate
        };

        // Save to localStorage
        localStorage.setItem(`gameStats_${mode}`, JSON.stringify(newStats));
        return newStats;
      });

      // Show all remaining categories regardless of win/loss
      const remainingCategories = categories
        .filter(cat => !completedRows.some(row => row.categoryIndex === categories.indexOf(cat)))
        .map((cat, index) => ({
          categoryIndex: categories.indexOf(cat),
          rowIndex: completedRows.length + index,
          category: cat
        }));
      
      setCompletedRows(prev => [...prev, ...remainingCategories]);
    }
    
    return () => clearInterval(interval);
  }, [completedRows.length, attempts, gameActive, timer, categories, mode]);

  const handleWordClick = (wordObj) => {
    if (!gameActive) return;
    
    if (selectedWords.some(w => w.word === wordObj.word)) {
      setSelectedWords(selectedWords.filter(w => w.word !== wordObj.word));
    } else if (selectedWords.length < 4) {
      const newSelected = [...selectedWords, wordObj];
      setSelectedWords(newSelected);
    }
  };

  const handleSubmit = () => {
    if (!gameActive) return;
    if (selectedWords.length !== 4) return;
    
    // Check for one away before doing anything else
    if (isOneAway(selectedWords)) {
      setShowOneAway(true);
      setTimeout(() => setShowOneAway(false), 2000);
    }
    
    // Get the category name for the first selected word
    const firstWord = selectedWords[0];
    const category = categories.find(cat => cat.words.includes(firstWord.word));
    
    if (!category) return;
    
    // Check if all selected words are in this category
    const allWordsInCategory = selectedWords.every(selected => 
      category.words.includes(selected.word)
    );

    if (allWordsInCategory) {
      // Correct selection
      const categoryIndex = categories.indexOf(category);
      const nextRowIndex = completedRows.length;
      const newCompletedRow = {
        categoryIndex,
        rowIndex: nextRowIndex,
        category
      };
      
      setCompletedRows(prev => [...prev, newCompletedRow]);
      setCategories(prev => {
        const updated = [...prev];
        updated[categoryIndex] = {
          ...updated[categoryIndex],
          completed: true
        };
        return updated;
      });
      
      setSelectedWords([]);
      handleShuffle();
    } else {
      // Incorrect selection
      setShake(true);
      setTimeout(() => setShake(false), 500);
      setSelectedWords([]);
      setAttempts(prev => prev - 1);
      
      if (isOneAway(selectedWords)) {
        setShowOneAway(true);
        setTimeout(() => setShowOneAway(false), 2000);
      }
    }
  };

  const handleShuffle = () => {
    const remainingWords = shuffledWords.filter(wordObj => 
      !completedRows.some(row => 
        row.category.words.includes(wordObj.word)
      )
    );
    setShuffledWords(shuffleArray([...remainingWords]));
  };

  const handleIncorrectGuess = () => {
    setShake(true);
    
    // Check for one away before clearing selection
    if (isOneAway(selectedWords)) {
      setShowOneAway(true);
      setTimeout(() => setShowOneAway(false), 2000);
    }
    
    setTimeout(() => setShake(false), 500);
    setSelectedWords([]);
    setAttempts(prev => prev - 1);
  };

  const resetGame = () => {
    setSelectedWords([]);
    setCompletedRows([]);
    setAttempts(4);
    setShowOneAway(false);
    setShake(false);
    setTimer(0);
    setGameActive(true);
    
    // Reset categories to initial state
    setCategories(initialCategories);

    // Create fresh shuffled words from all categories
    const allWords = initialCategories.flatMap(cat => 
      cat.words.map(word => ({
        word,
        categoryIndex: initialCategories.indexOf(cat)
      }))
    );
    
    setShuffledWords(shuffleArray(allWords));

    // Clear any selected class from word boxes
    const wordBoxes = document.querySelectorAll('.word-box');
    wordBoxes.forEach(box => {
      box.classList.remove('selected');
      box.classList.remove('correct');
      box.style.removeProperty('--gather-x');
      box.style.removeProperty('--gather-y');
      box.style.removeProperty('--sequence');
      box.style.removeProperty('--category-color');
    });

    // Clear localStorage
    localStorage.removeItem('gameCategories');
    localStorage.removeItem('shuffledWords');
    localStorage.removeItem('selectedWords');
    localStorage.removeItem('completedRows');
    localStorage.removeItem('attempts');
  };

  const handleNewGame = () => {
    // Clear correct saved game state for this mode only
    localStorage.removeItem(getStorageKey(mode));
    localStorage.removeItem(`gameStats_${mode}`);
    
    // Reset game state
    setSelectedWords([]);
    setCompletedRows([]);
    setAttempts(4);
    setShowOneAway(false);
    setShake(false);
    setTimer(0);
    setGameActive(true);
    setOpponents([]);
    setGameStartTime(Date.now());
    
    // Reset categories to initial state
    const newCategories = initialCategories.map(cat => ({...cat, completed: false}));
    setCategories(newCategories);

    // Create fresh shuffled words
    const allWords = newCategories.flatMap(cat => 
      cat.words.map(word => ({
        word,
        categoryIndex: initialCategories.indexOf(cat)
      }))
    );
    setShuffledWords(shuffleArray(allWords));

    // Clear DOM elements
    const wordBoxes = document.querySelectorAll('.word-box');
    wordBoxes.forEach(box => {
      box.classList.remove('selected');
      box.classList.remove('correct');
      box.style.removeProperty('--gather-x');
      box.style.removeProperty('--gather-y');
      box.style.removeProperty('--sequence');
      box.style.removeProperty('--category-color');
    });
  };

  useEffect(() => {
    const gameState = {
      categories,
      shuffledWords,
      selectedWords,
      completedRows,
      attempts,
      timer,
      gameActive,
      gameStartTime,
      opponents
    };
    localStorage.setItem(getStorageKey(mode), JSON.stringify(gameState));
  }, [categories, shuffledWords, selectedWords, completedRows, attempts, timer, gameActive, gameStartTime, opponents, mode]);

  useEffect(() => {
    if (mode === 'computer') {
      // Check if there's a saved computer opponent state
      const savedState = JSON.parse(localStorage.getItem(getStorageKey(mode))) || {};
      
      // Only initialize if there's no saved opponent or the game is fresh
      if (!savedState.opponents || !savedState.opponents.length) {
        setOpponents([{
          id: 'computer',
          name: 'ChatGPT 3.5',
          completedCategories: [],
          lives: 4
        }]);

        // Start computer moves
        const interval = setInterval(() => {
          if (gameActive) {
            setOpponents(prev => {
              const computerOpponent = prev.find(o => o.id === 'computer');
              if (computerOpponent && computerOpponent.completedCategories.length < 4) {
                const newCategories = [...computerOpponent.completedCategories];
                newCategories.push(newCategories.length + 1);
                
                const completionTime = newCategories.length === 4 ? Date.now() - gameStartTime : null;
                
                return prev.map(o => 
                  o.id === 'computer' 
                    ? {...o, completedCategories: newCategories, completionTime}
                    : o
                );
              }
              return prev;
            });
          }
        }, Math.random() * 5000 + 3000);

        return () => clearInterval(interval);
      }
    }
  }, [gameActive, gameStartTime, mode]);

  return (
    <div className="game">
      <StatsPanel timer={timer} gameStats={gameStats} />
      <div className="game-content">
        <div className="game-header">
          <Link to="/rooms" className="home-link">← Back to Lobby</Link>
          <h1>Connections Racer</h1>
          {!gameActive ? (
            <button className="new-game-button" onClick={handleNewGame}>
              New Game
            </button>
          ) : (
            <div className="attempts-counter">
              Lives left: {attempts}
              <div className="hearts-container">
                {[...Array(4)].map((_, index) => (
                  <Heart key={index} broken={index >= attempts} />
                ))}
              </div>
            </div>
          )}
        </div>
        
        <div className="playing-area">
          {showOneAway && (
            <div className="one-away-message">One away!</div>
          )}
          <div className={`word-grid ${shake ? 'shake' : ''}`}>
            {completedRows.map((completed, index) => (
              <div
                key={`completed-${index}`}
                className="completed-category-banner"
                style={{
                  backgroundColor: completed.category.color,
                  gridRow: index + 1,
                  gridColumn: '1 / -1'
                }}
              >
                <div className="category-name">{completed.category.name}</div>
                <div className="category-words">
                  {completed.category.words.join(', ')}
                </div>
              </div>
            ))}
            
            {shuffledWords
              .filter(wordObj => 
                !completedRows.some(row => 
                  row.category.words.includes(wordObj.word)
                )
              )
              .map((wordObj, index) => {
                const row = Math.floor(index / 4) + completedRows.length;
                const col = index % 4 + 1;
                
                return (
                  <div
                    key={`word-${index}`}
                    className={`word-box ${
                      selectedWords.some(
                        selected => selected.word === wordObj.word
                      ) ? 'selected' : ''
                    }`}
                    style={{
                      gridRow: row + 1,
                      gridColumn: col
                    }}
                    onClick={() => handleWordClick(wordObj)}
                  >
                    {wordObj.word}
                  </div>
                );
              })}
          </div>
        </div>

        <div className="game-buttons">
          <button 
            className="submit-button" 
            disabled={selectedWords.length !== 4}
            onClick={handleSubmit}
          >
            Submit
          </button>
          <button 
            className="shuffle-button" 
            onClick={handleShuffle}
            disabled={!gameActive}
          >
            Shuffle
          </button>
        </div>
      </div>
      {mode === 'computer' && (
        <OpponentPanel opponents={opponents} />
      )}
    </div>
  );
};

export default Game; 