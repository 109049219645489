import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Game from './Game';
import io from 'socket.io-client';
import './GameRoom.css';

const GameRoom = () => {
  const { roomId } = useParams();
  const navigate = useNavigate();
  const [room, setRoom] = useState(null);
  const [error, setError] = useState(null);
  const [socket, setSocket] = useState(null);
  const searchParams = new URLSearchParams(window.location.search);
  const mode = searchParams.get('mode');

  useEffect(() => {
    const newSocket = io(process.env.REACT_APP_BACKEND_URL || 'https://connectionracer-backend.onrender.com');
    setSocket(newSocket);
    
    if (roomId) {
      newSocket.emit('joinRoom', roomId);
    }

    newSocket.on('roomJoined', (roomData) => {
      setRoom(roomData);
    });

    newSocket.on('roomError', (error) => {
      setError(error);
      navigate('/');
    });

    return () => {
      if (roomId) {
        newSocket.emit('leaveRoom', roomId);
      }
      newSocket.disconnect();
    };
  }, [roomId, navigate]);

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!room) {
    return <div>Loading room...</div>;
  }

  return (
    <div className="game-room">
      <div className="room-info">
        <h2>Room: {room.name}</h2>
        <div className="players-list">
          {room.players.map(player => (
            <div key={player.id} className="player">
              {player.name}
            </div>
          ))}
        </div>
      </div>
      <Game roomId={roomId} socket={socket} mode={mode} />
    </div>
  );
};

export default GameRoom;
